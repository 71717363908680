import type { ReactNode } from 'react';
import { useState } from 'react';
import type { ButtonProps, StrictModalProps } from 'semantic-ui-react';
import { Button, Modal } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';

/** Props for the action buttons of the SaveCancelModal. */
export type ActionButtonProps = Omit<ButtonProps, 'onClick'> & {
	/** The dialog will be closed only if the onClick event resolves the Promise. Defaults to an empty Promise.resolve(). */
	onClick?: () => Promise<void>;
};

/** Returns the props of the default Save and Cancel buttons for the SaveCancelModal. */
export function getDefaultActionButtons(onSave: Callback<void>, onCancel?: Callback<void>): ActionButtonProps[] {
	return [
		{
			className: 'default-button-cancel',
			content: 'Cancel',
			onClick: async () => onCancel?.()
		},
		{
			className: 'default-button-save',
			positive: true,
			content: 'Save',
			onClick: async () => onSave(),
			labelPosition: 'right',
			icon: 'checkmark'
		}
	];
}

/** Props for SaveCancelModal. */
type SaveCancelModalProps = Omit<StrictModalProps, 'onClose' | 'onOpen' | 'closeIcon'> & {
	header: ReactNode;
	actionButtonProps: ActionButtonProps[];
	onCancel?: Callback<void>;
	onHide?: Callback<void>;
	id?: string;
};

/** Inline style that moves the Semantic UI modal close icon visually into the modal. */
export const MODAL_CLOSE_ICON_STYLE = { style: { top: '.5rem', right: '.5rem' }, color: 'black', name: 'close' };

/**
 * Provides a modal dialog with a Save and a Cancel button or with custom buttons. The actual content of the dialog must
 * be passed as children and the element that should trigger the dialog can be passed via the trigger prop.
 */
export function SaveCancelModal({
	header,
	actionButtonProps,
	onCancel,
	onHide,
	...props
}: SaveCancelModalProps): JSX.Element {
	const isOpenWhenRendered = props.trigger == null;
	const [open, setOpen] = useState(isOpenWhenRendered);

	const handleOnCancel = () => {
		onCancel?.();
		handleOnClose();
	};

	const handleOnClose = () => {
		setOpen(false);
		onHide?.();
	};

	return (
		<Modal
			onClose={handleOnCancel}
			onOpen={() => setOpen(true)}
			open={open}
			closeIcon={MODAL_CLOSE_ICON_STYLE}
			{...props}
		>
			<Modal.Header>{header}</Modal.Header>
			<Modal.Content>
				<Modal.Description>{props.children}</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				{actionButtonProps.map(({ content, onClick = () => Promise.resolve(), ...props }) => (
					<Button
						key={content}
						content={content}
						onClick={() => {
							onClick()
								.then(() => handleOnClose())
								// Ignore error as it only signals that the dialog should not be closed
								.catch(() => undefined);
						}}
						{...props}
					/>
				))}
			</Modal.Actions>
		</Modal>
	);
}
